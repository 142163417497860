import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Container, AppBar, Toolbar, Typography } from "@material-ui/core"
import styles from './header.module.scss'
import * as classNames from 'classnames';

const Header = ({ siteTitle, slogan }) => {
  const [isFixed, setIsFixed] = useState(false);

  const onScroll = (e) => {
    const isTopOfPage = (window.pageYOffset > 0);
    setIsFixed(isTopOfPage);
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    }
  }, []);

  const appBarClassName = classNames(styles.wrapper, {
    [styles.isFixed]: isFixed,
  });

  return (
    <AppBar color="transparent" className={appBarClassName}>
      <Toolbar style={{margin: '0 auto', textAlign: 'center'}}>
          <Link
            to="/"
            style={{
              color: `#333`,
              textDecoration: `none`,
            }}
          >
            <h1 className={styles.title}>{siteTitle}</h1>{slogan}
          </Link>
      </Toolbar>
    </AppBar>
  )
}


Header.propTypes = {
  siteTitle: PropTypes.string,
  slogan: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
