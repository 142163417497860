/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import "./layout.module.scss"
import { Container } from '@material-ui/core';
import Breadcrumbx from './Breadcrumbx';
import styles from './layout.module.scss'
import * as classNames from 'classnames';
import Header from './Header';

const Layout = ({ children, cat }) => {

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          slogan
        }
      }
    }
  `)

  const containerClassName = classNames(styles.container, {
    [styles.rooms]: cat === "rooms",
    [styles.catering]: cat === "catering",
  });

  const {title, slogan} = data.site.siteMetadata;

  return (
    <div className={containerClassName}>
      <Container maxWidth='md'>
        <Header siteTitle={title} slogan={slogan} />
        <div className={styles.wrapper}>
          {/* <Breadcrumbx /> */}
          <div>{children}</div>
          <footer><br />© {new Date().getFullYear()}, {title} - {slogan}{` `}<br /><br /></footer>
        </div>
      </Container>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
